import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header';
import Home from './pages/Home';
import Payoffs from './pages/Payoffs';
import Comparison from './pages/Comparison';
import Surface from './pages/Surface';

function App() {
  return <>
    <Router>
      <div className="container">
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/payoffs' element={<Payoffs />} />
          <Route path='/comparison' element={<Comparison />} />
          <Route path='/surface' element={<Surface />} />
        </Routes>
      </div>
    </Router>
    <ToastContainer />
  </>  
}

export default App;