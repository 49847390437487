import axios from 'axios'

// const API_URL = `http://${window.location.hostname}:8051/payoffs/`
const API_URL = "https://optvisdata.gberesearch.com/payoffs/"

const getPayoffs = async (payoff_type, payoff_direction, show_value) => {
  const response = await axios.get(
    API_URL, {
      params: {
        payoff_type: payoff_type,
        payoff_direction: payoff_direction,
        show_value: show_value  
      }
    }  
  )

  return response.data
}

const payoffsService = {
  getPayoffs
}

export default payoffsService
