import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import comparisonService from "./comparisonService";

const initialState = {
  comparisonData: [],
  isErrorComparison: false,
  isSuccessComparison: false,
  isLoadingComparison: false,
  messageComparison: ''
}

// Get payoff data
export const getComparison = createAsyncThunk(
  'comparison/data', 
  async (
    {
      spot, 
      strike, 
      ttm_2d, 
      int_rate, 
      div_yield, 
      imp_vol, 
      opt_type, 
      direction,
      xaxis,
      yaxis, 
      low_strike, 
      mid_strike, 
      high_strike, 
      low_ttm, 
      mid_ttm, 
      high_ttm, 
      ttm_shift
    },
    thunkAPI) => {
    try {
      return await comparisonService.getComparison(
        spot, 
        strike, 
        ttm_2d, 
        int_rate, 
        div_yield, 
        imp_vol, 
        opt_type, 
        direction,
        xaxis,
        yaxis, 
        low_strike, 
        mid_strike, 
        high_strike, 
        low_ttm, 
        mid_ttm, 
        high_ttm, 
        ttm_shift
      )
    } catch (error) {
      const message = 
        (error.response && 
          error.response.data && 
          error.response.data.message) || 
        error.message || 
        error.toString()

      return thunkAPI.rejectWithValue(message)  
    }
  }
)

export const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    resetComparison: (state) => {
      state.isLoadingComparison = false
      state.isErrorComparison = false
      state.isSuccessComparison = false
      state.messageComparison = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComparison.pending, (state) => {
        state.isLoadingComparison = true
      })
      .addCase(getComparison.fulfilled, (state, action) => {
        state.isLoadingComparison = false
        state.isSuccessComparison = true
        state.comparisonData = action.payload
      })
      .addCase(getComparison.rejected, (state, action) => {
        state.isLoadingComparison = false
        state.isErrorComparison = true
        state.messageComparison = action.payload
      })
  }
})

export const {resetComparison} = comparisonSlice.actions
export default comparisonSlice.reducer
