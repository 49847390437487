import axios from 'axios'

// const API_URL = `http://${window.location.hostname}:8051/surface/`
const API_URL = "https://optvisdata.gberesearch.com/surface/"

const getSurface = async (
  spot, 
  strike, 
  ttm, 
  int_rate, 
  div_yield, 
  imp_vol, 
  opt_type, 
  opt_data, 
  direction, 
  axis
  ) => {
  const response = await axios.get(
    API_URL, {
      params: {
        spot: spot, 
        strike: strike, 
        ttm: ttm, 
        int_rate: int_rate, 
        div_yield: div_yield, 
        imp_vol: imp_vol, 
        opt_type: opt_type, 
        opt_data: opt_data,
        direction: direction, 
        axis: axis 
      }
    }
  )

  return response.data
}

const surfaceService = {
  getSurface
}

export default surfaceService
