import { useSelector } from "react-redux"
import PayoffsPage from "../components/PayoffsPage"
import Spinner from "../components/Spinner"
import orientRefresh from "../components/orientRefresh"

function Payoffs () {
  const {isLoadingPayoffs} = useSelector((state) => state.payoffs)
  
  orientRefresh()

  if (isLoadingPayoffs) {
    <Spinner />
  }

  return (
    <>
      {!isLoadingPayoffs} {
        <>
          <PayoffsPage />
        </>
      }
    </>
  )
}

export default Payoffs