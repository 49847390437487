import axios from 'axios'

// const API_URL = `http://${window.location.hostname}:8051/comparison/`
const API_URL = "https://optvisdata.gberesearch.com/comparison/"

const getComparison = async (
  spot, 
  strike, 
  ttm_2d, 
  int_rate, 
  div_yield, 
  imp_vol, 
  opt_type, 
  direction,
  xaxis,
  yaxis, 
  low_strike, 
  mid_strike, 
  high_strike, 
  low_ttm, 
  mid_ttm, 
  high_ttm, 
  ttm_shift
  ) => {
  const response = await axios.get(
    API_URL, {
      params: {
        spot: spot, 
        strike: strike, 
        ttm_2d: ttm_2d, 
        int_rate: int_rate, 
        div_yield: div_yield, 
        imp_vol: imp_vol, 
        opt_type: opt_type, 
        direction: direction, 
        xaxis: xaxis,
        yaxis: yaxis, 
        low_strike: low_strike, 
        mid_strike: mid_strike, 
        high_strike: high_strike,  
        low_ttm: low_ttm, 
        mid_ttm: mid_ttm, 
        high_ttm: high_ttm, 
        ttm_shift: ttm_shift
      }
    }  
  )

  return response.data
}

const comparisonService = {
  getComparison
}

export default comparisonService
