import { configureStore } from '@reduxjs/toolkit';
import payoffsReducer from '../features/payoffs/payoffsSlice'
import comparisonReducer from '../features/comparison/comparisonSlice'
import surfaceReducer from '../features/surface/surfaceSlice'
import optionPriceReducer from '../features/optionPrice/optionPriceSlice'
import optionPriceTextReducer from '../features/optionPriceText/optionPriceTextSlice'


export const store = configureStore({
  reducer: {
    payoffs: payoffsReducer,
    comparison: comparisonReducer,
    surface: surfaceReducer,
    optionprice: optionPriceReducer,
    optionpricetext: optionPriceTextReducer,
  },
});
