import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getComparison, resetComparison } from "../features/comparison/comparisonSlice"
import ComparisonChart from "../components/ComparisonChart"

function ComparisonPage() {
  const {comparisonData} = useSelector((state) => state.comparison)
  const spot = 100
  const strike = 100
  const [ttm_2d, setTtm2d] = useState(90)
  const [int_rate, setIntRate] = useState(5.0)
  const [div_yield, setDivYield] = useState(0.0)
  const [imp_vol, setImpVol] = useState(20)
  const [opt_type, setOptType] = useState('call')
  const [direction, setDirection] = useState('long')
  const [xaxis, setXaxis] = useState('price')
  const [yaxis, setYaxis] = useState('value')
  const [low_strike, setLowStrike] = useState(90)
  const [mid_strike, setMidStrike] = useState(100)
  const [high_strike, setHighStrike] = useState(110)
  const [low_ttm, setLowTtm] = useState(90)
  const [mid_ttm, setMidTtm] = useState(180)
  const [high_ttm, setHighTtm] = useState(270)
  const [ttm_shift, setTtmShift] = useState(180)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getComparison({
      spot: spot, 
      strike: strike, 
      ttm_2d: ttm_2d, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type,
      direction: direction,
      xaxis: xaxis,
      yaxis: yaxis,
      low_strike: low_strike,
      mid_strike: mid_strike,
      high_strike: high_strike,
      low_ttm: low_ttm,
      mid_ttm: mid_ttm,
      high_ttm: high_ttm,
      ttm_shift: ttm_shift
    })).unwrap()
    dispatch(resetComparison)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const yaxisChange = (e) => {
    setXaxis('price');
    setYaxis(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(e)
    console.log(mid_strike)
    dispatch(getComparison({
      spot: 100, 
      strike: 100, 
      ttm_2d: ttm_2d, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type,
      direction: direction,
      xaxis: xaxis,
      yaxis: yaxis,
      low_strike: low_strike,
      mid_strike: mid_strike,
      high_strike: high_strike,
      low_ttm: low_ttm,
      mid_ttm: mid_ttm,
      high_ttm: high_ttm,
      ttm_shift: ttm_shift
    })).unwrap()
    dispatch(resetComparison)
  }

  return (
    <>
      <section className="comparison">
        <header className="comparison-header">
          <h2>3 Option Comparison</h2>
        </header>
        <div className="comparison-data">
          <div className="comparison-inputs">
            <form className="comparison-boxes" onSubmit={handleSubmit}> 
              <div className="comparison-groups">

                <div className="comparison-upper-group">                
                  <div className="radio-group">
                    <label className="field-labels">Direction</label>  
                    <div id="direction-radio">
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="long" 
                          id="direction-2d-long"
                          name="direction-2d" 
                          checked={direction === 'long'}
                          onChange={(e) => {setDirection(e.target.value)}}  
                        /> 
                        <label 
                          htmlFor='direction-2d-long'
                        >
                          Long
                        </label>
                      </div>
                      <div className="radiowrapper">  
                        <input 
                          type="radio" 
                          value="short"
                          id="direction-2d-short"
                          name="direction-2d" 
                          checked={direction === 'short'}
                          onChange={(e) => {setDirection(e.target.value)}}
                        />
                        <label 
                          htmlFor='direction-2d-short'
                        >
                          Short
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="radio-group">
                    <label className="field-labels">Y Axis</label>  
                    <div id="yaxis-radio">
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="value"
                          id="yaxis-value" 
                          name="yaxis" 
                          checked={yaxis === 'value'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-value'
                        >
                          Theoretical Value
                        </label>
                      </div>
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="delta"
                          id="yaxis-delta" 
                          name="yaxis" 
                          checked={yaxis === 'delta'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-delta'
                          className="hovertext"
                          data-hover="Sensitivity of the Option Price to changes in the Spot Price"
                        >
                          Delta
                        </label>
                      </div>
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="gamma"
                          id="yaxis-gamma" 
                          name="yaxis" 
                          checked={yaxis === 'gamma'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-gamma'
                          className="hovertext"
                          data-hover="Sensitivity of Delta to changes in the Spot Price; 2nd derivative of the Option Price with respect to the Spot Price"
                        >
                          Gamma
                        </label>
                      </div>
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="vega"
                          id="yaxis-vega" 
                          name="yaxis" 
                          checked={yaxis === 'vega'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-vega'
                          className="hovertext"
                          data-hover="Sensitivity of the Option Price to changes in Implied Volatility"
                        >
                          Vega
                        </label>
                      </div>
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="theta"
                          id="yaxis-theta" 
                          name="yaxis" 
                          checked={yaxis === 'theta'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-theta'
                          className="hovertext"
                          data-hover="Sensitivity of the Option Price to changes in Time To Expiration"
                        >
                          Theta
                        </label>
                      </div>
                      <div className="radiowrapper">
                        <input 
                          type="radio" 
                          value="rho"
                          id="yaxis-rho" 
                          name="yaxis" 
                          checked={yaxis === 'rho'}
                          onChange={yaxisChange}
                        /> 
                        <label 
                          htmlFor='yaxis-rho'
                          className="hovertext"
                          data-hover="Sensitivity of the Option Price to changes in the Interest Rate"
                        >
                          Rho
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="number-group">
                    <label className="field-labels">Volatility & Rate Data</label>
                    <label className="number-input">
                      <input 
                        type="number" 
                        name="int_rate" 
                        value={int_rate} 
                        step='0.01'
                        onChange={(e) => {setIntRate(e.target.value)}} 
                      />% - Interest Rate
                    </label>
                    <label className="number-input">
                      <input 
                        type="number" 
                        name="div_yield" 
                        value={div_yield} 
                        step='0.01'
                        onChange={(e) => {setDivYield(e.target.value)}} 
                      />% - Dividend Yield 
                    </label>
                    <label className="number-input">
                      <input 
                        type="number" 
                        name="imp_vol" 
                        value={imp_vol} 
                        step='0.01'
                        onChange={(e) => {setImpVol(e.target.value)}} 
                      />% - Implied Volatility
                    </label>
                  </div>
                </div>  
                
                { (yaxis !== 'rho') &&
                  <>
                    <div className="submit-button">
                      <input className="btn" type="submit" value="Calculate"></input>
                    </div>

                    <div className="comparison-lower-group">
                      <div className="radio-group">
                        <label className="field-labels">X Axis</label>  
                        <div id="xaxis-radio">
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="price"
                              id="xaxis-price" 
                              name="xaxis" 
                              checked={xaxis === 'price'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-price'
                            >
                              Underlying Price
                            </label>
                          </div>
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="time"
                              id="xaxis-time" 
                              name="xaxis" 
                              checked={xaxis === 'time'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-time'
                            >
                              Time To Expiration
                            </label>
                          </div>
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="vol"
                              id="xaxis-vol" 
                              name="xaxis" 
                              checked={xaxis === 'vol'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-vol'
                            >
                              Volatility
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="radio-group">
                        <label className="field-labels">Option Type</label> 
                        <div id="opt-type-radio">
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="call" 
                              id="opt-type-call-2d" 
                              name="opt_type-2d" 
                              checked={opt_type === 'call'}
                              onChange={(e) => {setOptType(e.target.value)}}  
                            /> 
                            <label 
                              htmlFor='opt-type-call-2d'
                            >
                              Call
                            </label>
                          </div>
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="put" 
                              id="opt-type-put-2d" 
                              name="opt_type-2d" 
                              checked={opt_type === 'put'}
                              onChange={(e) => {setOptType(e.target.value)}}  
                            /> 
                            <label 
                              htmlFor='opt-type-put-2d'
                            >
                              Put
                            </label>
                          </div>                        
                        </div>
                      </div>
                    
                      <div className="number-group">
                        <label className="field-labels">Option Strikes (%)</label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="lowStrike" 
                            value={low_strike} 
                            onChange={(e) => {setLowStrike(e.target.value)}} 
                          /> 
                        </label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="midStrike" 
                            value={mid_strike} 
                            onChange={(e) => {setMidStrike(e.target.value)}} 
                          /> 
                        </label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="highStrike" 
                            value={high_strike} 
                            onChange={(e) => {setHighStrike(e.target.value)}} 
                          /> 
                        </label>
                      </div>

                      <div className="number-group">
                        <label className="field-labels">Option Expiries (days)</label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="lowTtm" 
                            value={low_ttm} 
                            onChange={(e) => {setLowTtm(e.target.value)}} 
                          /> 
                        </label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="midTtm" 
                            value={mid_ttm} 
                            onChange={(e) => {setMidTtm(e.target.value)}} 
                          /> 
                        </label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="highTtm" 
                            value={high_ttm} 
                            onChange={(e) => {setHighTtm(e.target.value)}} 
                          /> 
                        </label>
                      </div>
                    </div>
                  </>
                }
                
                { (yaxis === 'rho') &&
                  <>
                    <div className="submit-button">
                      <input className="btn" type="submit" value="Calculate"></input>
                    </div>

                    <div className="comparison-lower-group">
                      <div className="radio-group">
                        <label className="field-labels">X Axis</label>  
                        <div id="xaxis-radio">
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="price"
                              id="xaxis-price" 
                              name="xaxis" 
                              checked={xaxis === 'price'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-price'
                            >
                              Underlying Price
                            </label>
                          </div>
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="strike"
                              id="xaxis-strike" 
                              name="xaxis" 
                              checked={xaxis === 'strike'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-strike'
                            >
                              Strike Price
                            </label>
                          </div>
                          <div className="radiowrapper">
                            <input 
                              type="radio" 
                              value="vol"
                              id="xaxis-vol" 
                              name="xaxis" 
                              checked={xaxis === 'vol'}
                              onChange={(e) => {setXaxis(e.target.value)}}
                            /> 
                            <label 
                              htmlFor='xaxis-vol'
                            >
                              Volatility
                            </label>
                          </div>
                        </div>
                      </div>
      
                      <div className="number-group">
                        <label className="field-labels">Option Expiries (days)</label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="ttm_2d" 
                            value={ttm_2d} 
                            onChange={(e) => {setTtm2d(e.target.value)}} 
                          /> 
                        </label>
                        <label className="number-input">
                          <input 
                            type="number" 
                            name="ttm_shift" 
                            value={ttm_shift} 
                            onChange={(e) => {setTtmShift(e.target.value)}} 
                          /> 
                        </label>
                      </div>    
                    </div>
                  </>
                }          
              </div>
            </form>
          </div>
          <div className="comparison-graph">
            <div className="comparison-graph-box">
              { 
                !comparisonData.vis_params && 
                <div></div>
              }
              { comparisonData.vis_params && <ComparisonChart visParams={comparisonData.vis_params} /> }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ComparisonPage
