import { useSelector } from "react-redux"
import ComparisonPage from "../components/ComparisonPage"
import SurfacePage from "../components/SurfacePage"
import PayoffsPage from "../components/PayoffsPage"
import Spinner from "../components/Spinner"
import orientRefresh from "../components/orientRefresh"

function Home () {
  const {isLoadingPayoffs, isLoadingComparison, isLoadingSurface} = useSelector((state) => state.payoffs)

  orientRefresh()

  if (isLoadingPayoffs || isLoadingComparison || isLoadingSurface) {
    <Spinner />
  }

  return (
    <>
      {!isLoadingPayoffs && !isLoadingComparison && !isLoadingSurface} {
        <>
          <SurfacePage />
          <ComparisonPage />
          <PayoffsPage />
        </>
      }
    </>
  )
}

export default Home