import Plot from 'react-plotly.js'

function SurfaceChart({graphParams, params}) {

  var data = [{
    x: graphParams['x'],
    y: graphParams['y'],
    z: graphParams['z'],
    type: 'surface',
    showscale: false,
              
    // set the colorscale to the chosen colorscheme
    colorscale: 'Jet',
    
    // Define the contours
    contours: {
      x: {
        show: true,
        start: graphParams['x_start'],
        end: graphParams['x_stop'],
        size: graphParams['x_size'],
        color: "white"
      },
      y: {
        show: true,
        start: graphParams['y_start'],
        end: graphParams['y_stop'],
        size: graphParams['y_size'],
        color: "white"
      },
      z: {
        show: true,
        start: graphParams['z_start'],
        end: graphParams['z_stop'],
        size: graphParams['z_size']
      }
    },
  }];
      
  var layout = {
    scene: {
      camera: {eye: {x: 1.9, y: 1.4, z: 1.3}},
      xaxis: {
        backgroundcolor: '#d7dbe9', 
        gridcolor: "#a5b1cd",
        zerolinecolor: "#a5b1cd",
        showbackground: true,
        autorange: 'reversed',

        title: {
          text: graphParams['axis_label2'],
          font: {
            color: "#282b38",
            family: "Poppins, monospace",
            size: 10,
          },
        },
        tickfont: {
          size: 10
        },
      },
      yaxis: {
        backgroundcolor: '#d7dbe9', 
        gridcolor: "#a5b1cd",
        zerolinecolor: "#a5b1cd",
        showbackground: true,
        // autorange: 'reversed',
        title: {
          text: graphParams['axis_label1'],
          font: {
            color: "#282b38",
            family: "Poppins, monospace",
            size: 10,
          },
        },
        tickfont: {
          size: 10
        },
      },
      zaxis: {
        backgroundcolor: '#d7dbe9', 
        gridcolor: "#a5b1cd",
        zerolinecolor: "#a5b1cd",
        showbackground: true,       
        title: {
          text: graphParams['axis_label3'],
          font: {
            color: "#282b38",
            family: "Poppins, monospace",
            size: 10,
          },
        },
        tickfont: {
          size: 10
        },
      },
      
    },
    autosize: true,
    margin: {
      l: 0,
      r: 0, 
      b: 0, 
      t: 0
    },
  }

  var config = {
    displayModeBar: false
  }

  return (
    <>
      <Plot 
        data={data}
        layout={layout}
        config={config}
      />
    </>
  )
}

export default SurfaceChart