import axios from 'axios'

// const API_URL = `http://${window.location.hostname}:8051/optionprice/`
const API_URL = "https://optvisdata.gberesearch.com/optionprice/"

const getOptionPrice = async (
  opt_data,
  spot, 
  strike, 
  ttm, 
  int_rate, 
  div_yield, 
  imp_vol, 
  opt_type  
  ) => {
  const response = await axios.get(
    API_URL, {
      params: {
        opt_data: opt_data,
        spot: spot, 
        strike: strike, 
        ttm: ttm, 
        int_rate: int_rate, 
        div_yield: div_yield, 
        imp_vol: imp_vol, 
        opt_type: opt_type        
      }
    }  
  )

  return response.data
}

const optionPriceService = {
  getOptionPrice
}

export default optionPriceService