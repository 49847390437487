import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import optionPriceService from "./optionPriceService";

const initialState = {
  optionPriceData: [],
  isErrorOptionPrice: false,
  isSuccessOptionPrice: false,
  isLoadingOptionPrice: false,
  messageOptionPrice: ''
}

// Get option price data
export const getOptionPrice = createAsyncThunk(
  'optionprice/data', 
  async (
    {
      opt_data,
      spot, 
      strike, 
      ttm, 
      int_rate, 
      div_yield, 
      imp_vol, 
      opt_type    
    },
    thunkAPI) => {
    try {
      return await optionPriceService.getOptionPrice(
        opt_data,
        spot, 
        strike, 
        ttm, 
        int_rate, 
        div_yield, 
        imp_vol, 
        opt_type        
      )
    } catch (error) {
      const message = 
        (error.response && 
          error.response.data && 
          error.response.data.message) || 
        error.message || 
        error.toString()

      return thunkAPI.rejectWithValue(message)  
    }
  }
)

export const optionPriceSlice = createSlice({
  name: 'optionprice',
  initialState,
  reducers: {
    resetOptionPrice: (state) => {
      state.isLoadingOptionPrice = false
      state.isErrorOptionPrice = false
      state.isSuccessOptionPrice = false
      state.messageOptionPrice = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptionPrice.pending, (state) => {
        state.isLoadingOptionPrice = true
      })
      .addCase(getOptionPrice.fulfilled, (state, action) => {
        state.isLoadingOptionPrice = false
        state.isSuccessOptionPrice = true
        state.optionPriceData = action.payload
      })
      .addCase(getOptionPrice.rejected, (state, action) => {
        state.isLoadingOptionPrice = false
        state.isErrorOptionPrice = true
        state.messageOptionPrice = action.payload
      })
  }
})

export const {resetOptionPrice} = optionPriceSlice.actions
export default optionPriceSlice.reducer
