export default function PayoffButton ({ value, checkValue, onChange }) {
  const textLabel = value.replace(
    /(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()
  );
  const checkedValue = value.replace(/\s/g, "-")
  
  const showMap = {
    'true': 'On',
    'false': 'Off'
  }

  let payoffDiv = <div></div>

  if (value === 'long' || value ==='short') {
    payoffDiv = (
      <div className="radiowrapper">
        <input 
          type="radio" 
          value={value}  
          id={`payoff-direction-${value}`}
          name="payoff-direction" 
          checked={checkValue === value}
          onChange={onChange}
        /> 
        <label 
          htmlFor={`payoff-direction-${value}`}
        >
          {textLabel}
        </label>
      </div>
    );
  } else if (value === 'true' || value === 'false') {
    payoffDiv = (
      <div className="radiowrapper">
        <input 
          type="radio" 
          value={textLabel} 
          id={`value-${value}`}
          name="show-value" 
          checked={checkValue === textLabel}
          onChange={onChange}
        /> 
        <label 
          htmlFor={`value-${value}`}
        >
          {showMap[value]}
        </label>
      </div>
    );
  } else {
    payoffDiv = (
      <div className="radiowrapper">
      <input 
        type="radio" 
        value={value} 
        id={`type-${checkedValue}`}
        name="payoff-type"
        checked={checkValue === value}
        onChange={onChange}
      /> 
      <label 
        htmlFor={`type-${checkedValue}`}
      >
        {textLabel}
      </label>
    </div>
    );
  }

  return (
    payoffDiv
  )
}