import { useSelector } from "react-redux"
import SurfacePage from "../components/SurfacePage"
import orientRefresh from "../components/orientRefresh"

function Surface () {
  const {isLoadingSurface} = useSelector((state) => state.payoffs)

  orientRefresh()

  window.addEventListener('orientationchange', doOnOrientationChange);

  function doOnOrientationChange()
  {
    if (screen.height > screen.width) {
      console.log('portrait');
      window.location.reload()
    } else {
      console.log('landscape');
      window.location.reload()
    }
  }

  if (isLoadingSurface) {
    <Spinner />
  }

  return (
    <>
      {!isLoadingSurface} {
        <>
          <SurfacePage />
        </>
      }
    </>
  )
}

export default Surface