import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import surfaceService from "./surfaceService";

const initialState = {
  surfaceData: [],
  isErrorSurface: false,
  isSuccessSurface: false,
  isLoadingSurface: false,
  messageSurface: ''
}

// Get payoff data
export const getSurface = createAsyncThunk(
  'surface/data', 
  async (
    {
      spot, 
      strike, 
      ttm, 
      int_rate, 
      div_yield, 
      imp_vol, 
      opt_type, 
      opt_data, 
      direction, 
      axis
    },
    thunkAPI) => {
    try {
      return await surfaceService.getSurface(
        spot, 
        strike, 
        ttm, 
        int_rate, 
        div_yield, 
        imp_vol, 
        opt_type, 
        opt_data, 
        direction, 
        axis
      )
    } catch (error) {
      const message = 
        (error.response && 
          error.response.data && 
          error.response.data.message) || 
        error.message || 
        error.toString()

      return thunkAPI.rejectWithValue(message)  
    }
  }
)

export const surfaceSlice = createSlice({
  name: 'surface',
  initialState,
  reducers: {
    resetSurface: (state) => {
      state.isLoadingSurface = false
      state.isErrorSurface = false
      state.isSuccessSurface = false
      state.messageSurface = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurface.pending, (state) => {
        state.isLoadingSurface = true
      })
      .addCase(getSurface.fulfilled, (state, action) => {
        state.isLoadingSurface = false
        state.isSuccessSurface = true
        state.surfaceData = action.payload
      })
      .addCase(getSurface.rejected, (state, action) => {
        state.isLoadingSurface = false
        state.isErrorSurface = true
        state.messageSurface = action.payload
      })
  }
})

export const {resetSurface} = surfaceSlice.actions
export default surfaceSlice.reducer
