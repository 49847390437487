import { useSelector } from "react-redux"
import ComparisonPage from "../components/ComparisonPage"
import Spinner from "../components/Spinner"
import orientRefresh from "../components/orientRefresh"

function Comparison () {
  const {isLoadingComparison} = useSelector((state) => state.payoffs)

  orientRefresh()

  if (isLoadingComparison) {
    <Spinner />
  }

  return (
    <>
      {!isLoadingComparison} {
        <>
          <ComparisonPage />
        </>
      }
    </>
  )
}

export default Comparison