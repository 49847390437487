import Plot from 'react-plotly.js'

function ComparisonChart({visParams}) {
  const graphLineWidth = 3

  var optionOne = {
    x: visParams['xarray'],
    y: visParams['yarray1'],
    line: {
      color: 'blue', 
      width: graphLineWidth
    },
    name: visParams['label1'],
    type: 'scatter',
  };

  var optionTwo = {
    x: visParams['xarray'],
    y: visParams['yarray2'],
    line: {
      color: 'red',
      width: graphLineWidth
    },
    name: visParams['label2'],
    type: 'scatter',
  };
  
  var optionThree = {
    x: visParams['xarray'],
    y: visParams['yarray3'],
    line: {
      color: 'green',
      width: graphLineWidth
    },
    name: visParams['label3'],
    type: 'scatter',
  };

  if(visParams['label4'] !== null) {
    var optionFour = {
      x: visParams['xarray'],
      y: visParams['yarray4'],
      line: {
        color: 'orange',
        width: graphLineWidth
      },
      name: visParams['label4'],
      type: 'scatter',
    };
  }

  var layout = {
    xaxis: {
      title: {
        text: visParams['xlabel'],
        font: {
          size: 12,
          family: "Poppins, monospace",
          color: 'black', 
        }
      },
      showline: true,
      linewidth: 0.5,
      linecolor: "#6a7284",
      mirror: true,
      range: [visParams['xmin'], visParams['xmax']],
      gridwidth: 1,
      gridcolor: "#6a7284",
      zeroline: false,
    },
    yaxis: {
      title: {
        text: visParams['ylabel'],
        font: {
          size: 12,
          family: "Poppins, monospace",
          color: 'black', 
        }
      },
      showline: true,
      linewidth: 0.5,
      linecolor: "#6a7284",
      mirror: true,
      range: [visParams['ymin'], visParams['ymax']],
      gridwidth: 0.5,
      gridcolor: "#6a7284",
      zeroline: false,
    },
    font: {
      color: 'black',
      family: "Poppins, monospace",
      size: 10,
    },
    margin: {
      l:60, 
      r:50, 
      b:60, 
      t:70
    },
    autosize: true,
    paper_bgcolor: 'white', 
    plot_bgcolor: '#d7dbe9', 
    legend: {
      x: 0.05,
      y: 0.95,
      traceorder: "normal",
      bgcolor: 'rgba(0, 0, 0, 0)',
      font: {
        family: "Poppins, monospace",
        size: 10,
        color: 'black'
      },
    },
  }

  var comparisonData

  if(visParams['label4'] !== null) {
    comparisonData = [optionOne, optionTwo, optionThree, optionFour]
  } else {
    comparisonData = [optionOne, optionTwo, optionThree]
  }  

  var config = {
    displayModeBar: false
  }

  return (
    <>
      <Plot 
        data={comparisonData}
        layout={layout}
        config={config}
      />
    </>
  )
}

export default ComparisonChart