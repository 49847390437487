export default function orientRefresh() {
  window.addEventListener('orientationchange', doOnOrientationChange);

  function doOnOrientationChange()
  {
    if (screen.height > screen.width) {
      console.log('portrait');
      window.location.reload()
    } else {
      console.log('landscape');
      window.location.reload()
    }
  }
}