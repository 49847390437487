import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import payoffsService from "./payoffsService";

const initialState = {
  payoffsData: [],
  isErrorPayoffs: false,
  isSuccessPayoffs: false,
  isLoadingPayoffs: false,
  messagePayoffs: ''
}

// Get payoff data
export const getPayoffs = createAsyncThunk(
  'payoffs/data', 
  async ({payoff_type, payoff_direction, show_value}, thunkAPI) => {
    try {
      return await payoffsService.getPayoffs(
        payoff_type, 
        payoff_direction, 
        show_value
      )
    } catch (error) {
      const message = 
        (error.response && 
          error.response.data && 
          error.response.data.message) || 
        error.message || 
        error.toString()

      return thunkAPI.rejectWithValue(message)  
    }
  }
)

export const payoffsSlice = createSlice({
  name: 'payoffs',
  initialState,
  reducers: {
    resetPayoffs: (state) => {
      state.isLoadingPayoffs = false
      state.isErrorPayoffs = false
      state.isSuccessPayoffs = false
      state.messagePayoffs = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayoffs.pending, (state) => {
        state.isLoadingPayoffs = true
      })
      .addCase(getPayoffs.fulfilled, (state, action) => {
        state.isLoadingPayoffs = false
        state.isSuccessPayoffs = true
        state.payoffsData = action.payload
      })
      .addCase(getPayoffs.rejected, (state, action) => {
        state.isLoadingPayoffs = false
        state.isErrorPayoffs = true
        state.messagePayoffs = action.payload
      })
  }
})

export const {resetPayoffs} = payoffsSlice.actions
export default payoffsSlice.reducer
