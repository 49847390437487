import Plot from 'react-plotly.js'

function PayoffChart({payoffDict}) {
  const graphLineWidth = 3

  var optionPayoff = {
    x: payoffDict['SA'],
    y: payoffDict['payoff'],
    type: 'scatter',
    line: {
      color: 'blue', 
      width: graphLineWidth
    },
    name: 'Payoff'
  };

  if (payoffDict['payoff2'] !== null) {
    var optionValue = {
      x: payoffDict['SA'],
      y: payoffDict['payoff2'],
      type: 'scatter',
      line: {
        color: 'red', 
        width: graphLineWidth
      },
      name: 'Value'
    };
  }

  var layout = {
    xaxis: {
      showline: true,
      linewidth: 2,
      linecolor: "#6a7284",
      mirror: true,
      gridwidth: 1,
      gridcolor: "#6a7284",
      zeroline: false,
      title: {
        text: 'Underlying Price',
        font:{
          size: 12,
          family: "Poppins, monospace",
          color: 'black',
        }
      }
    },
    yaxis: {
      showline: true,
      linewidth: 2,
      linecolor: "#6a7284",
      mirror: true,
      gridwidth: 1,
      gridcolor: "#6a7284",
      title: {
        text: 'P&L',
        font:{
          size: 12,
          family: "Poppins, monospace",
          color: 'black',
        }
      }
    },
    font: {
      color: 'black',
      family: "Poppins, monospace",
      size: 12,
    },
    paper_bgcolor: 'white', 
    plot_bgcolor: '#d7dbe9', 
    legend: {
      x: 0.05,
      y: 0.95,
      traceorder: "normal",
      bgcolor: 'rgba(0, 0, 0, 0)',
      font: {
        family: "Poppins, monospace",
        size: 10,
        color: 'black'
      },
    },
    margin: {
      l:60, 
      r:50, 
      b:60, 
      t:70
    },
    shapes: [
      {
        type: 'line',
        yref: 'paper',
        x0: payoffDict['S'],
        y0: 0,
        x1: payoffDict['S'],
        y1: 1,
        line: {
          color: 'black', 
          width: 1,
        }
      },
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        y0: 0,
        x1: 1,
        y1: 0,
        line: {
          color: 'black', 
          width: 1,
        }
      }
    ]
  }

  var payoffData

  if (payoffDict['payoff2'] !== null) {
    payoffData = [optionPayoff, optionValue]  
  } else {
    payoffData = [optionPayoff]
  }

  var config = {
    displayModeBar: false
  }

  return (
    <>
      <Plot 
        data={payoffData}
        layout={layout}
        config={config}
      />
    </>
  )
}

export default PayoffChart