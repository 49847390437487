import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getSurface, resetSurface } from "../features/surface/surfaceSlice"
import { getOptionPriceText, resetOptionPriceText } from "../features/optionPriceText/optionPriceTextSlice"
import SurfaceChart from "../components/SurfaceChart"

function SurfacePage() {
  const {surfaceData} = useSelector((state) => state.surface)
  const {optionPriceTextData} = useSelector((state) => state.optionpricetext)

  const [spot, setSpot] = useState(100)
  const [strike, setStrike] = useState(100)
  const [ttm, setTtm] = useState(90)
  const [int_rate, setIntRate] = useState(5.0)
  const [div_yield, setDivYield] = useState(0.0)
  const [imp_vol, setImpVol] = useState(20)
  const [opt_type, setOptType] = useState('call')
  const [opt_data, setOptData] = useState('price')
  const [direction, setDirection] = useState('long')
  const [axis, setAxis] = useState('price')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSurface({
      spot: spot, 
      strike: strike, 
      ttm: ttm, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type,
      opt_data: opt_data,
      direction: direction,
      axis: axis
    })).unwrap();
    dispatch(resetSurface);
    dispatch(getOptionPriceText({
      opt_data: opt_data,
      spot: spot, 
      strike: strike, 
      ttm: ttm, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type
    }));
    dispatch(resetOptionPriceText);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(getSurface({
      spot: spot, 
      strike: strike, 
      ttm: ttm, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type,
      opt_data: opt_data,
      direction: direction,
      axis: axis
    })).unwrap();
    dispatch(resetSurface);
    dispatch(getOptionPriceText({
      opt_data: opt_data,
      spot: spot, 
      strike: strike, 
      ttm: ttm, 
      int_rate: int_rate, 
      div_yield: div_yield, 
      imp_vol: imp_vol, 
      opt_type: opt_type
    })).unwrap();
    dispatch(resetOptionPriceText)
  }

  return (
    <>
      <section className="surface">
        <header className="surface-header">
          <h2>Option Sensitivity Surface</h2>
        </header>
        <div className="surface-data">
          <div className="surface-inputs">
            <form className="surface-boxes" onSubmit={handleSubmit}>
              <div className="surface-groups">
                <div className="radio-group">
                  <label className="field-labels">Option Sensitivity</label>  
                  <div id="opt-data-radio">
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="price"
                        id="opt-data-price" 
                        name="opt_data" 
                        checked={opt_data === 'price'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-price'
                      >
                        Option Price
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="delta"
                        id="opt-data-delta" 
                        name="opt_data" 
                        checked={opt_data === 'delta'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-delta'
                        className="hovertext"
                        data-hover="Sensitivity of the Option Price to changes in the Spot Price"
                      >
                        Delta
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="gamma"
                        id="opt-data-gamma" 
                        name="opt_data" 
                        checked={opt_data === 'gamma'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-gamma'
                        className="hovertext"
                        data-hover="Sensitivity of Delta to changes in the Spot Price; 2nd derivative of the Option Price with respect to the Spot Price"
                      >
                        Gamma
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="vega"
                        id="opt-data-vega" 
                        name="opt_data" 
                        checked={opt_data === 'vega'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-vega'
                        className="hovertext"
                        data-hover="Sensitivity of the Option Price to changes in Implied Volatility"
                      >
                        Vega
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="theta"
                        id="opt-data-theta" 
                        name="opt_data" 
                        checked={opt_data === 'theta'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-theta'
                        className="hovertext"
                        data-hover="Sensitivity of the Option Price to changes in Time To Expiration"
                      >
                        Theta
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="rho"
                        id="opt-data-rho" 
                        name="opt_data" 
                        checked={opt_data === 'rho'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-rho'
                        className="hovertext"
                        data-hover="Sensitivity of the Option Price to changes in the Interest Rate"
                      >
                        Rho
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="vomma"
                        id="opt-data-vomma" 
                        name="opt_data" 
                        checked={opt_data === 'vomma'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-vomma'
                        className="hovertext"
                        data-hover="Sensitivity of Vega to changes in Implied Volatility; 2nd derivative of the Option Price with respect to Implied Volatility; Volga; Vol Gamma"
                      >
                        Vomma
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="vanna"
                        id="opt-data-vanna" 
                        name="opt_data" 
                        checked={opt_data === 'vanna'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-vanna'
                        className="hovertext"
                        data-hover="Sensitivity of Delta to changes in Implied Volatility; Sensitivity of Vega to changes in the Spot Price"
                      >
                        Vanna
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="zomma"
                        id="opt-data-zomma" 
                        name="opt_data" 
                        checked={opt_data === 'zomma'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-zomma'
                        className="hovertext"
                        data-hover="Sensitivity of Gamma to changes in Implied Volatility"
                      >
                        Zomma
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="color"
                        id="opt-data-color" 
                        name="opt_data" 
                        checked={opt_data === 'color'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-color'
                        className="hovertext"
                        data-hover="Sensitivity of Gamma to changes in Time To Expiration; Gamma Theta"
                      >
                        Color
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="vega bleed"
                        id="opt-data-vega-bleed" 
                        name="opt_data" 
                        checked={opt_data === 'vega bleed'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-vega-bleed'
                        className="hovertext"
                        data-hover="Sensitivity of Vega to changes in Time To Expiration"
                      >
                        Vega Bleed
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="charm"
                        id="opt-data-charm" 
                        name="opt_data" 
                        checked={opt_data === 'charm'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-charm'
                        className="hovertext"
                        data-hover="Sensitivity of Delta to changes in Time To Expiration; Delta Bleed"
                      >
                        Charm
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="speed"
                        id="opt-data-speed" 
                        name="opt_data" 
                        checked={opt_data === 'speed'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-speed'
                        className="hovertext"
                        data-hover="Sensitivity of Gamma to changes in the Spot Price; 3rd derivative of the Option Price with respect to the Spot Price"
                      >
                        Speed
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="ultima"
                        id="opt-data-ultima" 
                        name="opt_data" 
                        checked={opt_data === 'ultima'}
                        onChange={(e) => {setOptData(e.target.value)}}
                      /> 
                      <label 
                        htmlFor='opt-data-ultima'
                        className="hovertext"
                        data-hover="Sensitivity of Vomma to changes in Implied Volatility; 3rd derivative of the Option Price with respect to Implied Volatility"
                        >
                        Ultima
                      </label>
                    </div>
                  </div>
                </div>
                    
                <div className="radio-group">
                  <label className="field-labels">Option Type</label> 
                  <div id="opt-type-radio">
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="call" 
                        id="opt-type-call-3d" 
                        name="opt_type-3d" 
                        checked={opt_type === 'call'}
                        onChange={(e) => {setOptType(e.target.value)}}  
                      /> 
                      <label 
                        htmlFor='opt-type-call-3d'
                      >
                        Call
                      </label>
                    </div>
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="put" 
                        id="opt-type-put-3d" 
                        name="opt_type-3d" 
                        checked={opt_type === 'put'}
                        onChange={(e) => {setOptType(e.target.value)}}  
                      /> 
                      <label 
                        htmlFor='opt-type-put-3d'
                      >
                        Put
                      </label>
                    </div>                        
                  </div>
                </div>

                <div className="radio-group">
                  <label className="field-labels">Direction</label>  
                  <div id="direction-radio">
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="long" 
                        id="direction-3d-long"
                        name="direction-3d" 
                        checked={direction === 'long'}
                        onChange={(e) => {setDirection(e.target.value)}}  
                      /> 
                      <label 
                        htmlFor='direction-3d-long'
                      >
                        Long
                      </label>
                    </div>
                    <div className="radiowrapper">  
                      <input 
                        type="radio" 
                        value="short"
                        id="direction-3d-short"
                        name="direction-3d" 
                        checked={direction === 'short'}
                        onChange={(e) => {setDirection(e.target.value)}}
                      />
                      <label 
                        htmlFor='direction-3d-short'
                      >
                        Short
                      </label>
                    </div>
                  </div>
                </div>
                
                <div className="radio-group">
                  <label className="field-labels">Axis</label>  
                  <div id="axis-radio">
                    <div className="radiowrapper">
                      <input 
                        type="radio" 
                        value="price" 
                        id="axis-long"
                        name="payoff-axis" 
                        checked={axis === 'price'}
                        onChange={(e) => {setAxis(e.target.value)}}  
                      /> 
                      <label 
                        htmlFor='axis-long'
                      >
                        Price
                      </label>
                    </div>
                    <div className="radiowrapper">  
                      <input 
                        type="radio" 
                        value="vol"
                        id="axis-short"
                        name="payoff-axis" 
                        checked={axis === 'vol'}
                        onChange={(e) => {setAxis(e.target.value)}}
                      />
                      <label 
                        htmlFor='axis-short'
                      >
                        Volatility
                      </label>
                    </div>
                  </div>
                </div>

                <div className="number-group">
                  <label className="field-labels">Option Data</label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="spot" 
                      value={spot} 
                      onChange={(e) => {setSpot(e.target.value)}} 
                    /> - Spot Price
                  </label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="strike" 
                      value={strike} 
                      step='0.01'
                      onChange={(e) => {setStrike(e.target.value)}} 
                    /> - Strike Price 
                  </label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="ttm" 
                      value={ttm} 
                      step='0.01'
                      onChange={(e) => {setTtm(e.target.value)}} 
                    /> days - Time To Expiration
                  </label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="int_rate" 
                      value={int_rate} 
                      step='0.01'
                      onChange={(e) => {setIntRate(e.target.value)}} 
                    />% - Interest Rate
                  </label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="div_yield" 
                      value={div_yield} 
                      step='0.01'
                      onChange={(e) => {setDivYield(e.target.value)}} 
                    />% - Dividend Yield 
                  </label>
                  <label className="number-input">
                    <input 
                      type="number" 
                      name="imp_vol" 
                      value={imp_vol} 
                      step='0.01'
                      onChange={(e) => {setImpVol(e.target.value)}} 
                    />% - Implied Volatility
                  </label>
                </div>
              </div>              

              <div className="submit-button">
                <input className="btn" type="submit" value="Calculate"></input>
              </div>
            </form>

            <div className="opt-price">
              <div className="opt-price-box">
                { 
                  !optionPriceTextData.option_data && 
                  <div></div>
                }
                { 
                  optionPriceTextData.option_data && (
                  <p className="opt-price-text">{optionPriceTextData.option_data.result}</p>
                  )
                }
              </div>
            </div>
          </div>

          <div className="surface-graph">
            <div className="surface-graph-box">
              { 
                !surfaceData.graph_params && 
                <div></div>
              }
              { surfaceData.graph_params && <SurfaceChart graphParams={surfaceData.graph_params} params={surfaceData.params}/> }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SurfacePage