import { NavLink } from "react-router-dom"

function Header() {
  return (
    <header className="header">
      <h1>Option Visualizer</h1>
      <div className="links">
        <div className="logo">
          <NavLink to='/' className={({isActive}) => (isActive ? "active" : null)}>Home</NavLink> 
        </div>
        <div>
          <ul className="sub-links">
            <>
              <li>
                <NavLink to='/payoffs' className={({isActive}) => (isActive ? "active" : null)}>
                  Payoffs
                </NavLink>
              </li>
              <li>
                <NavLink to='/comparison' className={({isActive}) => (isActive ? "active" : null)}>
                  Comparison
                </NavLink>
              </li>        
              <li>
                <NavLink to='/surface' className={({isActive}) => (isActive ? "active" : null)}>
                  Surface
                </NavLink>
              </li>        
            </>
          </ul>
        </div>
      </div>
    </header>
  )
}
export default Header
