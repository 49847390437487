import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import optionPriceTextService from "./optionPriceTextService";

const initialState = {
  optionPriceTextData: [],
  isErrorOptionPriceText: false,
  isSuccessOptionPriceText: false,
  isLoadingOptionPriceText: false,
  messageOptionPriceText: ''
}

// Get option price text data
export const getOptionPriceText = createAsyncThunk(
  'optionprice/text', 
  async (
    {
      opt_data,
      spot, 
      strike, 
      ttm, 
      int_rate, 
      div_yield, 
      imp_vol, 
      opt_type    
    },
    thunkAPI) => {
    try {
      return await optionPriceTextService.getOptionPriceText(
        opt_data,
        spot, 
        strike, 
        ttm, 
        int_rate, 
        div_yield, 
        imp_vol, 
        opt_type        
      )
    } catch (error) {
      const message = 
        (error.response && 
          error.response.data && 
          error.response.data.message) || 
        error.message || 
        error.toString()

      return thunkAPI.rejectWithValue(message)  
    }
  }
)

export const optionPriceTextSlice = createSlice({
  name: 'optionpricetext',
  initialState,
  reducers: {
    resetOptionPriceText: (state) => {
      state.isLoadingOptionPriceText = false
      state.isErrorOptionPriceText = false
      state.isSuccessOptionPriceText = false
      state.messageOptionPriceText = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptionPriceText.pending, (state) => {
        state.isLoadingOptionPriceText = true
      })
      .addCase(getOptionPriceText.fulfilled, (state, action) => {
        state.isLoadingOptionPriceText = false
        state.isSuccessOptionPriceText = true
        state.optionPriceTextData = action.payload
      })
      .addCase(getOptionPriceText.rejected, (state, action) => {
        state.isLoadingOptionPriceText = false
        state.isErrorOptionPriceText = true
        state.messageOptionPriceText = action.payload
      })
  }
})

export const {resetOptionPriceText} = optionPriceTextSlice.actions
export default optionPriceTextSlice.reducer
