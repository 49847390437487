import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPayoffs, resetPayoffs } from '../features/payoffs/payoffsSlice'
import PayoffChart from "../components/PayoffChart"
import PayoffButton from "./PayoffButton"

function PayoffsPage() {
  const {payoffsData} = useSelector((state) => state.payoffs)
  const [payoffType, setPayoffType] = useState('call')
  const [payoffDirection, setPayoffDirection] = useState('long')
  const [showValue, setShowValue] = useState('True')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPayoffs({
      payoff_type: payoffType, 
      payoff_direction: payoffDirection, 
      show_value: showValue
    })).unwrap();
    dispatch(resetPayoffs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const payoffChange = (e) => {
    setPayoffType(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: e.target.value, 
        payoff_direction: payoffDirection, 
        show_value: showValue
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }
  
  const directionChange = (e) => {
    setPayoffDirection(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: payoffType, 
        payoff_direction: e.target.value, 
        show_value: showValue
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }
  
  const showValueChange = (e) => {
    setShowValue(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: payoffType, 
        payoff_direction: payoffDirection, 
        show_value: e.target.value
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }

  if (payoffsData === undefined | []) {
    return <div></div> 
  }

  return (
    <>
      <section className="payoffs">
        <header className="payoffs-header">
          <h2>Payoff Diagrams</h2>
        </header>
        <div className="payoffs-data">
          <div className="payoffs-inputs">
            <form>
              <div className="radio-group">
                <label className="field-labels">Payoff Type</label>
                <PayoffButton 
                  value="call" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="put" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="stock" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="forward" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="collar" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="spread" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="backspread" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="ratio vertical spread" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="straddle" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="strangle" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="butterfly" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="christmas tree" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="condor" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="iron butterfly" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
                <PayoffButton 
                  value="iron condor" 
                  checkValue={payoffType} 
                  onChange={payoffChange} 
                />
              </div>
              <div className="radio-group">
                <label className="field-labels">Direction</label>  
                <div id="direction-radio">
                  <PayoffButton 
                    value="long" 
                    checkValue={payoffDirection} 
                    onChange={directionChange} 
                  />
                  <PayoffButton 
                    value="short" 
                    checkValue={payoffDirection} 
                    onChange={directionChange} 
                  />
                </div>
              </div>
              <div className="radio-group">
                <label className="field-labels">Show Value</label>   
                <div className="value-radio">
                  <PayoffButton 
                    value="true" 
                    checkValue={showValue} 
                    onChange={showValueChange} 
                  />
                  <PayoffButton 
                    value="false" 
                    checkValue={showValue} 
                    onChange={showValueChange} 
                  />
                </div>
              </div>            
            </form>
          </div>
          <div className="payoffs-graph">
            <div className="payoffs-graph-box">
              { 
                !payoffsData.payoff_dict && 
                <div></div>
              }
              { payoffsData.payoff_dict && <PayoffChart payoffDict={payoffsData.payoff_dict} /> }
            </div>
          </div>
        </div>
      </section>
    </>
  )  
}

export default PayoffsPage